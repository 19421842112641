.animated {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
}

@-webkit-keyframes fadeinup {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeinup {
    0% {
        opacity: 0;
        -moz-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-ms-keyframes fadeinup {
    0% {
        opacity: 0;
        -ms-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -ms-transform: translateY(0);
    }
}

@-o-keyframes fadeinup {
    0% {
        opacity: 0;
        -o-transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -o-transform: translateY(0);
    }
}

@keyframes fadeinup {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeinup {
    -webkit-animation-name: fadeinup;
    -moz-animation-name: fadeinup;
    -ms-animation-name: fadeinup;
    -o-animation-name: fadeinup;
    animation-name: fadeinup;
}

@-webkit-keyframes fadeindown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@-moz-keyframes fadeindown {
    0% {
        opacity: 0;
        -moz-transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -moz-transform: translateY(0);
    }
}

@-ms-keyframes fadeindown {
    0% {
        opacity: 0;
        -ms-transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -ms-transform: translateY(0);
    }
}

@-o-keyframes fadeindown {
    0% {
        opacity: 0;
        -ms-transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        -ms-transform: translateY(0);
    }
}

@keyframes fadeindown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeindown {
    -webkit-animation-name: fadeindown;
    -moz-animation-name: fadeindown;
    -ms-animation-name: fadeindown;
    -o-animation-name: fadeindown;
    animation-name: fadeindown;
}

@-webkit-keyframes bouncein {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.3);
    }

    50% {
        opacity: 1;
        -webkit-transform: scale(1.05);
    }

    70% {
        -webkit-transform: scale(0.9);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

@-moz-keyframes bouncein {
    0% {
        opacity: 0;
        -moz-transform: scale(0.3);
    }

    50% {
        opacity: 1;
        -moz-transform: scale(1.05);
    }

    70% {
        -moz-transform: scale(0.9);
    }

    100% {
        -moz-transform: scale(1);
    }
}

@-ms-keyframes bouncein {
    0% {
        opacity: 0;
        -ms-transform: scale(0.3);
    }

    50% {
        opacity: 1;
        -ms-transform: scale(1.05);
    }

    70% {
        -ms-transform: scale(0.9);
    }

    100% {
        -ms-transform: scale(1);
    }
}

@-o-keyframes bouncein {
    0% {
        opacity: 0;
        -o-transform: scale(0.3);
    }

    50% {
        opacity: 1;
        -o-transform: scale(1.05);
    }

    70% {
        -o-transform: scale(0.9);
    }

    100% {
        -o-transform: scale(1);
    }
}

@keyframes bouncein {
    0% {
        opacity: 0;
        transform: scale(0.3);
    }

    50% {
        opacity: 1;
        transform: scale(1.05);
    }

    70% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.bouncein {
    -webkit-animation-name: bouncein;
    -moz-animation-name: bouncein;
    -ms-animation-name: bouncein;
    -o-animation-name: bouncein;
    animation-name: bouncein;
}
