#weather-calendar {
    display: flex;
    flex-direction: row;
}

#weather-calendar > span {
    -ms-flex: 1;
    flex: auto;
    height: 100%;
}

#weather {
    position: relative;
    zoom: 1;
    width: 500px;
}

#weather-inner {
    margin: auto;
    width: 200px;
}

#weather .now {
    float: left;
    text-align: center;
    display: flex;
}

#weather .now > span {
    -ms-flex: 1;
    flex: 1;
    height: 100%;
}

#weather .now .condition {
    font-family: Weather, sans-serif;
    font-size: 7em;
    height: 170px;
    margin: 0 0 7px 10px;

    /* vertically center */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#weather .now .temp {
    height: 170px;
    margin: 0 0 7px 10px;

    /* vertically center */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#weather .now b {
    font-size: 3em;
    font-weight: bold;
    font-family: 'Helvetica Neue', Open-Reg, sans-serif;
}

#weather .now p {
    font-size: 2em;
}

#air-message {
    font-size: 1.8em;
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.8);
}

.main-return, .wunder {
    cursor: pointer;
    position: fixed;
    bottom: 10px;
    color: #555;
    text-decoration: none;
    font-size: 2em;
    -webkit-transition: all 200ms ease-in-out;
    z-index: 999999;
}

.wunder path {
    -webkit-transition: all 200ms ease-in-out;
    fill: #555;
}

.wunder {
    right: 90px;
}
