html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
    font-family: HelveticaNeue-UltraLight, 'Helvetica Neue UltraLight', 'Helvetica Neue', Open-Light, sans-serif;
    background-color: #fff;
    margin: 0 auto;
    height: 100%;
}

ol, ul {
    list-style: none;
}

@media all and (width <= 750px) {
    body #weather ul {
        display: none;
    }

    body #weather {
        position: static;
        width: 100%;
        padding: 0;
    }

    body #weather .now {
        margin: 0 auto;
        float: none;
    }

    body #content {
        width: 100%;
        height: 100%;
        left: auto;
        margin-left: 0;
    }

    body .second {
        display: none !important;
    }

    body #time {
        display: none;
    }

    body #face {
        stroke-width: 5px;
    }

    body #ticks {
        stroke-width: 1;
    }

    body #hourhand {
        stroke-width: 4px;
    }

    body #minutehand {
        stroke-width: 2px;
    }

    body #secondhand {
        stroke-width: 1px;
    }

    body #numbers {
        font-family: sans-serif;
        font-size: 7pt;
        text-anchor: middle;
        stroke: none;
    }

    body .main-return {
        font-size: 1.3em;
        bottom: 5px;
    }

    body .wunder {
        bottom: 5px;
        right: 60px;
    }
}

@font-face {
    font-family: Weather;
    src: url('../font/weather.woff') format('truetype');
    font-display: block;
}

@font-face {
    font-family: Open-Light;
    src: url('../font/OpenSans-Light.ttf') format('truetype');
    font-display: block;
}

@font-face {
    font-family: Open-Reg;
    src: url('../font/OpenSans-Regular.ttf') format('truetype');
    font-display: block;
}

html {
    min-height: 100%;
    height: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    background-color: #111;
    background-repeat: repeat;
}

main {
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

a {
    text-decoration: none;
}

.title {
    font-size: 2em;
    color: white;
    text-align: center;
    font-weight: normal;
    margin: 30px 0;
    font-family: 'Helvetica Neue', Open-Reg, sans-serif;
}

.light-text {
    color: rgba(255, 255, 255, 0.8);
}

.light-text .title {
    color: rgba(255, 255, 255, 0.3);
}

.light-text .wunder path {
    fill: rgba(255, 255, 255, 0.3);
}

.hover-highlight:hover path {
    fill: #fff;
}

.light-text #weather .hour, .light-text #weather .now p {
    -webkit-text-stroke: 1px rgba(255, 255, 255, 0.8);
}

.clearfix {
    zoom: 1;
}

.clearfix::before, .clearfix::after {
    content: '';
    display: table;
}

.clearfix::after {
    clear: both;
}

.hidden {
    display: none;
}

#content {
    width: 840px;
    height: 608px;
    position: absolute;
    top: 10%;
    left: 50%;
    margin-left: -420px;
}

#time {
    font-size: 12em;
    text-align: center;
}

#time span {
    display: inline-block;
    width: 280px;
    text-align: center;
    -webkit-transition: none;
    -webkit-transform: rotate3d(0, 0, 0, 0);
}

.wunder a {
    font-size: 1em;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.3);
}

#time span .invisible {
    display: inline;
    visibility: hidden;
}
